<template>
  <echarts-com v-loading="loading" id="parkingDensity-crossingDensity" :options="options"/>
</template>

<script>
import * as echarts from "echarts";

export default {
  components:{
    echartsCom:()=>import("@/components/echartsCom")
  },
  data() {
    return {
      options:{},
      loading:false
    }
  },
  methods:{
    async getOptions(params={}){
      try {
        this.loading = true;
        let res = await this.$http.post("/statistics/parking/time/point",params);
        if (res.code === 0) {
          let xAxis_data = []
          let arriveCount = []
          let departCount = []

          for(let item of res.data){
            xAxis_data.push(item.timePoint)
            arriveCount.push(item.arriveCount)
            departCount.push(item.departCount)
          }


          this.$set(this, "options", {
            tooltip: {
              trigger: "axis",
              extraCssText: "box-shadow: 0px 0px 13px rgba(0, 193, 153, 0.22);",
              padding: [15, 10],
            },
            legend: {
              right: "0%",
              top: '0%',
              data: ['入场', '出场'],
              textStyle:{
                color:"white"
              }
            },
            xAxis: {
              type: "category",
              data: xAxis_data,
              axisTick: {
                show: false,
              },
              axisLine: {
                lineStyle: {
                  color: "#2a4d69",
                },
              },
              axisLabel: {
                color: "white",
              },
            },
            yAxis: {
              type: "value",
              splitLine: {
                lineStyle: {
                  color: "#2b4a6e",
                  type: "dashed",
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#2a4d69",
                },
              },
              axisLabel: {
                color: "white",
              },
            },
            grid: {
              left: "0%",
              width: "100%",
              height: "90%",
              bottom: "0%",
              containLabel: true, // 包含坐标轴中的文字
            },
            series: [
              {
                name:"入场",
                type: "bar",
                barMaxWidth: 25,
                itemStyle: {
                  barBorderRadius: [33, 33, 0, 0],
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {offset: 0, color: "#00cec0"},
                    {offset: 1, color: "#9de5e0"},
                  ]),
                },
                data: arriveCount,
              },
              {
                name:"出场",
                type: "bar",
                barMaxWidth: 25,
                itemStyle: {
                  barBorderRadius: [33, 33, 0, 0],
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {offset: 0, color: "#FFBF5A"},
                    {offset: 1, color: "rgba(255,191,90,0.4)"},
                  ]),
                },
                data: departCount,
              },
            ],
          });
        }else{
          this.$message.error(res.msg);
        }
        this.loading = false;
      } catch {
        this.$message.error("车位数量统计获取失败");
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="scss">
#parkingDensity-crossingDensity {

}
</style>
